import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        listCategoriesForSearch: [],
        pageKey: 0,
        modals: [],
        prerender: !!window.PRERENDER,
        theme: localStorage.getItem("theme")
            ? localStorage.getItem("theme")
            : window.matchMedia &&
              window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light",
        testUsers: [],
        darkThemeTestListOnly:
            process.env.VUE_APP_DEMO_DARK_THEME === "false" ? false : true,
    },
    mutations: {
        SET_CATEGORIES_FOR_SEARCH(state, data) {
            state.listCategoriesForSearch = data;
        },
        SET_THEME(state, theme) {
            state.theme = theme;
        },
        SET_TEST_USERS(state, testUsers) {
            state.testUsers = testUsers;
        },
    },
    actions: {
        setTheme({ commit }, theme) {
            commit("SET_THEME", theme);
            document.documentElement.setAttribute("data-theme", this.theme);
            localStorage.setItem("theme", theme);
        },
        setTestUsers({ commit }, testUsers) {
            commit("SET_TEST_USERS", testUsers);
        },
    },
    getters: {
        CATEGORIES_FOR_SEARCH: (state) => {
            return state.listCategoriesForSearch;
        },
        isUserInTestList: (state) => {
            return state.testUsers.includes(state.user.user?.user_id);
        },
        showDarkThemeFeature: (state, getters) => {
            return state.darkThemeTestListOnly
                ? getters.isUserInTestList
                : true;
        },
        themeClass: (state, getters) => {
            return getters.showDarkThemeFeature ? state.theme : "";
        },
    },
    modules: {
        user: require("./user").default,
        category: require("./category").default,
        brand: require("./brand").default,
        seller: require("./seller").default,
        common: require("./common").default,
        product: require("./product").default,
        table: require("./table").default,
        list: require("./list").default,
        listM: require("./listM").default,
        priceVar: require("./priceVar").default,
        rating: require("./rating").default,
        feedback: require("./feedback").default,
        partner: require("./partner").default,
        billing: require("./billing").default,
        connections: require("./connections").default,
        seo: require("./seo").default,
        reviewManagement: require("./reviewManagement").default,
        repricer: require("./repricer").default,
        keyword: require("./keyword").default,
    },
});
